<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="searchDialog" persistent max-width="600px" width="600px">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="searchDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>انشاء تقرير</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text>
          <div style="margin-top: 10px"></div>
          <v-autocomplete v-model="sectionName" :items="$store.state.sections" item-text="sectionName" outlined item-value="sectionName" label="القسم"></v-autocomplete>
          <v-autocomplete v-model="studyType" :items="studyTypes" item-text="name" item-value="name" outlined label="الدراسة"></v-autocomplete>
          <v-autocomplete v-model="statusId" :items="status" item-text="name" item-value="id" outlined label="الحالة المالية"></v-autocomplete>
          <v-autocomplete
            v-model="acceptedTypeId"
            outlined
            color="primary"
            label="قناة القبول"
            :items="$store.state.acceptedTypes"
            item-text="acceptedName"
            item-value="idAcceptedType"
            item-color="primary"
          ></v-autocomplete>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="searchData"> بحث </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2" fab small color="primary" @click="searchDialog = true" v-bind="attrs" v-on="on">
          <v-icon> mdi-magnify </v-icon>
        </v-btn>
      </template>
      <span>بحث</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  data: () => ({
    searchDialog: false,
    sectionName: "",
    acceptedTypeId: "",
    overlay: false,
    studyType: "",
    statusId: "",
    status: [
      { name: "المسددين", id: 1 },
      { name: "غير المسددين", id: 2 },
      { name: "الجميع", id: 3 },
    ],
    studyTypes: [{ name: "صباحي" }, { name: "مسائي" }],
    levels: [
      { levelName: "المرحلة الاولى", level: 1 },
      { levelName: "المرحلة الثانية", level: 2 },
      { levelName: "المرحلة الثالثة", level: 3 },
      { levelName: "المرحلة الرابعة", level: 4 },
    ],
    level: "",
  }),
  created() {},
  methods: {
    searchData() {
      let self = this;
      self.overlay = true;

      let query = "";

      if (self.studyType) {
        query += "&studyType=" + self.studyType;
      }

      if (self.acceptedTypeId) {
        query += "&acceptedTypeId=" + self.acceptedTypeId;
      }

      self.$http
        .get(`${self.$store.state.apiUrlApplication}/applicationReportSearch?sectionName=${self.sectionName}${query}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res.data);
          self.overlay = false;
          let data = {
            students: res.data,
            statusId: self.statusId,
          };
          this.$emit("clicked", data);
        });
      this.searchDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
