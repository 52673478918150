<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container class="container not-printable" fluid>
      <v-card elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="9">
            <h3>جميع الاستمارات</h3>
          </v-col>
          <v-col cols="3" style="text-align: left">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mx-2" fab small color="primary" @click="exportExcel()" v-bind="attrs" v-on="on">
                  <v-icon> mdi-microsoft-excel </v-icon>
                </v-btn>
              </template>
              <span>تصدير EXCEL</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container class="container not-printable" fluid>
      <v-card elevation="6">
        <table border="1">
          <tr>
            <th>التسلسل</th>
            <th>اسم الطالب</th>
            <th>رقم الاستمارة</th>
            <th>الرقم الجامعي</th>
            <th>اسم الام</th>
            <th>رقم الهاتف</th>
            <th>الجنس</th>
            <th>سنة التولد</th>
            <th>الرقم الامتحاني</th>
            <th>الرقم السري</th>
            <th>التربية</th>
            <th>اسم المدرسة</th>
            <th>هوية الاحوال</th>
            <th>المعدل</th>
            <th>المجموع</th>
            <th>الدور</th>
            <th>الفرع</th>
            <th>سنة التخرج</th>
            <th>الترشيح الاول</th>
            <th>الدراسة</th>
            <th>الكلية</th>
            <th>الترشيح الثاني</th>
            <th>الدراسة</th>
            <th>الكلية</th>
            <th>الترشيح الثالث</th>
            <th>الدراسة</th>
            <th>الكلية</th>
            <th>الترشيح الرابع</th>
            <th>الدراسة</th>
            <th>الكلية</th>
            <th>قناة القبول</th>
            <th>الملاحظات</th>
            <th>اسم المستخدم</th>
            <th>كلمة المرور</th>
            <th>حالة الحساب</th>
          </tr>
          <tr v-for="(student, index) in allApplications" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ student.studentName }}</td>
            <td>{{ student.applicationNumber }}</td>
            <td>{{ student.collegeNumber }}</td>
            <td>{{ student.motherName }}</td>
            <td>{{ student.studentPhone }}</td>
            <td>{{ student.gender == "1" || student.gender == "ذكر" ? "ذكر" : "انثى" }}</td>
            <td>{{ student.dob }}</td>
            <td>{{ student.examNumber }}</td>
            <td>{{ student.secretNumber }}</td>
            <td>{{ student.education }}</td>
            <td>{{ student.schoolName }}</td>
            <td>{{ student.nationalNumber }}</td>
            <td>{{ student.average }}</td>
            <td>{{ student.total }}</td>
            <td>{{ student.attempt }}</td>
            <td>{{ student.categoryName }}</td>
            <td>{{ student.graduationYear }}</td>
            <td>{{ student.firstSectionName == "none" ? " " : student.firstSectionName }}</td>
            <td>{{ student.firstStudyType == "none" ? " " : student.firstStudyType }}</td>
            <td>{{ student.firstUniversity == "none" ? " " : student.firstUniversity }}</td>
            <td>{{ student.secondSectionName == "none" ? " " : student.secondSectionName }}</td>
            <td>{{ student.secondStudyType == "none" ? " " : student.secondStudyType }}</td>
            <td>{{ student.secondUniversity == "none" ? " " : student.secondUniversity }}</td>
            <td>{{ student.thirdSectionName == "none" ? " " : student.thirdSectionName }}</td>
            <td>{{ student.thirdStudyType == "none" ? " " : student.thirdStudyType }}</td>
            <td>{{ student.thirdUniversity == "none" ? " " : student.thirdUniversity }}</td>
            <td>{{ student.fourthSectionName == "none" ? " " : student.fourthSectionName }}</td>
            <td>{{ student.fourthStudyType == "none" ? " " : student.fourthStudyType }}</td>
            <td>{{ student.fourthUniversity == "none" ? " " : student.fourthUniversity }}</td>
            <td>{{ student.acceptedName }}</td>
            <td>{{ student.notes }}</td>
            <td>t_{{ student.collegeNumber }}</td>
            <td>{{ student.password }}</td>
            <td>{{ student.isRegistered == 1 ? "تم التسجيل في نظام القبول المركزي" : " " }}</td>
          </tr>
        </table>
      </v-card></v-container
    >
  </div>
</template>

<script>
const Excel = require("exceljs");
export default {
  data() {
    return {
      allApplications: [],
      overlay: false,
    };
  },
  created() {
    this.initialization();
  },
  methods: {
    initialization() {
      let self = this;
      self.overlay = true;
      self.$http
        .get(`${self.$store.state.apiUrlApplication}/allApplication`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res.data);
          self.allApplications = res.data;
          self.overlay = false;
        })
        .catch((err) => {
          console.log(err);
          self.overlay = false;
        });
    },
    exportExcel() {
      let self = this;
      const workbook = new Excel.Workbook();

      const worksheet = workbook.addWorksheet("استمارات القبول");
      worksheet.views = [{ rightToLeft: true }];

      worksheet.columns = [
        { header: "اسم الطالب", key: "studentName", width: 50 },
        { header: "رقم الاستمارة", key: "applicationNumber", width: 20 },
        { header: "الرقم الجامعي", key: "collegeNumber", width: 20 },
        { header: "اسم الام", key: "motherName", width: 50 },
        { header: "رقم الهاتف", key: "studentPhone", width: 50 },
        { header: "الجنس", key: "gender", width: 50 },
        { header: "سنة التولد", key: "dob", width: 50 },
        { header: "الرقم الامتحاني", key: "examNumber", width: 50 },
        { header: "الرقم السري", key: "secretNumber", width: 50 },
        { header: "التربية", key: "education", width: 50 },
        { header: "اسم المدرسة", key: "schoolName", width: 50 },
        { header: "هوية الاحوال", key: "nationalNumber", width: 50 },
        { header: "المعدل", key: "average", width: 50 },
        { header: "المجموع", key: "total", width: 50 },
        { header: "الدور", key: "attempt", width: 50 },
        { header: "الفرع", key: "categoryName", width: 50 },
        { header: "سنة التخرج", key: "graduationYear", width: 50 },
        { header: "الترشيح الاول", key: "firstSectionName", width: 50 },
        { header: "الدراسة", key: "firstStudyType", width: 50 },
        { header: "الكلية", key: "firstUniversity", width: 50 },
        { header: "الترشيح الثاني", key: "secondSectionName", width: 50 },
        { header: "الدراسة", key: "secondStudyType", width: 50 },
        { header: "الكلية", key: "secondUniversity", width: 50 },
        { header: "الترشيح الثالث", key: "thirdSectionName", width: 50 },
        { header: "الدراسة", key: "thirdStudyType", width: 50 },
        { header: "الكلية", key: "thirdUniversity", width: 50 },
        { header: "الترشيح الرابع", key: "fourthSectionName", width: 50 },
        { header: "الدراسة", key: "fourthStudyType", width: 50 },
        { header: "الكلية", key: "fourthUniversity", width: 50 },
        { header: "قناة القبول", key: "acceptedName", width: 50 },
        { header: "الملاحظات", key: "notes", width: 50 },
        { header: "اسم المستخدم", key: "userName", width: 50 },
        { header: "كلمة المرور", key: "password", width: 50 },
        { header: "حالة الحساب", key: "isRegistered", width: 50 },
      ];
      self.allApplications.forEach((student) => {
        worksheet.addRow({
          studentName: student.studentName,
          applicationNumber: student.applicationNumber,
          collegeNumber: student.collegeNumber,
          motherName: student.motherName,
          gender: student.gender,
          dob: student.dob,
          examNumber: student.examNumber,
          secretNumber: student.secretNumber,
          education: student.education,
          schoolName: student.schoolName,
          nationalNumber: student.nationalNumber,
          average: student.average,
          total: student.total,
          attempt: student.attempt,
          categoryName: student.categoryName,
          graduationYear: student.graduationYear,
          firstSectionName: student.firstSectionName == "none" ? " " : student.firstSectionName,
          firstStudyType: student.firstStudyType == "none" ? " " : student.firstStudyType,
          firstUniversity: student.firstUniversity == "none" ? " " : student.firstUniversity,
          secondSectionName: student.secondSectionName == "none" ? " " : student.secondSectionName,
          secondStudyType: student.secondStudyType == "none" ? " " : student.secondStudyType,
          secondUniversity: student.secondUniversity == "none" ? " " : student.secondUniversity,
          thirdSectionName: student.thirdSectionName == "none" ? " " : student.thirdSectionName,
          thirdStudyType: student.thirdStudyType == "none" ? " " : student.thirdStudyType,
          thirdUniversity: student.thirdUniversity == "none" ? " " : student.thirdUniversity,
          fourthSectionName: student.fourthSectionName == "none" ? " " : student.fourthSectionName,
          fourthStudyType: student.fourthStudyType == "none" ? " " : student.fourthStudyType,
          fourthUniversity: student.fourthUniversity == "none" ? " " : student.fourthUniversity,
          acceptedName: student.acceptedName,
          notes: student.notes,
          userName: `t_${student.collegeNumber}`,
          password: student.password,
          isRegistered: student.isRegistered == 1 ? "تم التسجيل في نظام القبول المركزي" : " ",
        });
      });

      worksheet.eachRow(function (row, rowNumber) {
        row.eachCell((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
      });

      worksheet.getRow(1).eachCell((cell) => {
        cell.font = { bold: true };
        cell.alignment = { vertical: "middle", horizontal: "center" };
        cell.fill = {
          type: "pattern",
          pattern: "darkTrellis",
          fgColor: { argb: "FFFFFF00" },
          bgColor: { argb: "FF0000FF" },
        };
      });

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = `applicationReport.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}
tr th,
tr td {
  text-align: center !important;
  padding: 3px !important;
}
</style>
