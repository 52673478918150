<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-container class="container" fluid>
      <v-card elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="12">
            <h3>صفحة الاستمارات المدفوعة من المالية</h3>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container class="container" fluid>
      <v-alert type="warning">
        <h4>اكتب اسم الطالب ومن ثم اضغط على ENTER في خانة البحث</h4>
      </v-alert>
    </v-container>

    <v-container class="container" fluid>
      <v-card elevation="6">
        <v-card-title>
          <v-text-field v-model="search" @change="searchStudent()" v-on:keyup.enter="searchStudent()" outlined append-icon="mdi-magnify" label="بحث" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="formApplication" :items-per-page="15" item-key="idStudent" class="table-content">
          <template v-slot:[`item.index`]="{ index }">
            <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
          </template>

          <template v-slot:[`item.level`]="{ item }">
            <p style="padding: 0 3px">{{ item.level | setLevel }}</p>
          </template>

          <template v-slot:[`item.studyType`]="{ item }">
            <p style="padding: 0 3px">{{ item.studyType | setStudyType }}</p>
          </template>

          <template v-slot:[`item.amount`]="{ item }">
            <p style="padding: 0 3px">
              {{
                item.amount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "IQD",
                })
              }}
            </p>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      overlay: false,
      formApplication: [],
      headers: [
        { text: "التسلسل", value: "index" },
        { text: "اسم الطالب", value: "studentName" },
        { text: "رقم الاستمارة", value: "formNumber" },
        { text: "تاريخ الاستمارة", value: "applicationDateFormat" },
        { text: "المبلغ", value: "amount" },
      ],
    };
  },
  methods: {
    searchStudent() {
      let self = this;
      self.overlay = true;
      if (self.search) {
        self.$http
          .get(`${self.$store.state.apiUrlFees}/searchStudentName/${self.search}`, {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          })
          .then((res) => {
            self.overlay = false;
            console.log(res.data);
            self.formApplication = res.data;
          })
          .catch((e) => {
            console.log(e);
            self.overlay = false;
          });
      } else {
        self.overlay = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
