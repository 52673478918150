import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import Report from "../views/Report.vue";
import Login from "../views/Login.vue";
import Application from "../views/Application.vue";
import FormApplication from "../views/FormApplication.vue";
import AllApplication from "../views/AllApplication.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
  },
  {
    path: "/report",
    name: "Report",
    component: Report,
  },
  {
    path: "/form",
    name: "FormApplication",
    component: FormApplication,
  },
  {
    path: "/all",
    name: "AllApplication",
    component: AllApplication,
  },
  {
    path: "/application",
    name: "Application",
    component: Application,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
