<template>
  <div>
    <v-container class="container not-printable" fluid>
      <v-card elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="10">
            <v-text-field v-model="student" @keyup.enter="searchData()" outlined color="primary" label="رقم الاستمارة او اسم الطالب" :rules="studentRequiredFormRules"></v-text-field>
          </v-col>
          <v-col cols="2" style="padding: 20px">
            <v-btn tile color="primary" @click="searchData()">
              <v-icon left> mdi-text-box-search </v-icon>
              بحث
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container class="container not-printable" fluid v-if="show">
      <v-container class="container" fluid v-if="studentData.isConfirm == 1">
        <v-alert type="error">
          <h4>هذا الطالب مثبت في النظام يرجى عدم تعديل البيانات</h4>
        </v-alert>
      </v-container>

      <v-card elevation="6" style="padding: 20px">
        <div v-if="$store.state.userData.roleId != 61">
          <v-row>
            <v-col cols="10">
              <v-text-field v-model="studentData.applicationNumber" outlined color="primary" label="رقم الاستمارة" disabled></v-text-field>
            </v-col>
            <v-col cols="10">
              <v-text-field v-model="studentData.collegeNumber" outlined color="primary" label="الرقم الاحصائي" disabled></v-text-field>
            </v-col>
            <v-col cols="10">
              <v-text-field v-model="studentData.studentName" outlined color="primary" label="اسم الطالب الرباعي" :rules="studentRequiredFormRules"></v-text-field>
            </v-col>
            <v-col cols="10">
              <v-text-field v-model="studentData.studentPhone" outlined color="primary" label="رقم الهاتف" :rules="studentRequiredFormRules"></v-text-field>
            </v-col>
            <v-col cols="10">
              <v-text-field v-model="studentData.motherName" outlined color="primary" label="اسم الام الثلاثي" :rules="studentRequiredFormRules"></v-text-field>
            </v-col>
            <v-col cols="10">
              <v-text-field v-model="studentData.examNumber" outlined color="primary" label="الرقم الامتحاني" :rules="studentRequiredFormRules"></v-text-field>
            </v-col>
            <v-col cols="10">
              <v-text-field v-model="studentData.password" outlined color="primary" label="الرقم السري" :rules="studentRequiredFormRules"></v-text-field>
            </v-col>
            <v-col cols="10">
              <v-text-field v-model="studentData.dob" outlined color="primary" label="تاريخ الميلاد" :rules="studentRequiredFormRules"></v-text-field>
            </v-col>

            <v-col cols="10">
              <v-text-field v-model="studentData.nationalNumber" outlined color="primary" label="رقم هوية الاحوال المدنية" :rules="studentRequiredFormRules"></v-text-field>
            </v-col>

            <v-col cols="10">
              <v-autocomplete
                v-model="studentData.categoryId"
                outlined
                color="primary"
                label="الفرع"
                :items="$store.state.categories"
                item-text="categoryName"
                item-value="idCategory"
                item-color="primary"
                :rules="studentRequiredFormRules"
              ></v-autocomplete>
            </v-col>

            <v-col cols="10">
              <v-text-field v-model="studentData.graduationYear" outlined color="primary" label="سنة التخرج" :rules="studentRequiredFormRules"></v-text-field>

              <!-- <v-autocomplete
                v-model="studentData.graduationYear"
                outlined
                color="primary"
                label="سنة التخرج"
                :items="$store.state.years"
                item-text="year"
                item-value="year"
                item-color="primary"
                :rules="studentRequiredFormRules"
              ></v-autocomplete> -->
            </v-col>

            <v-col cols="10">
              <v-text-field v-model="studentData.average" outlined color="primary" type="number" label="المعدل" :rules="studentRequiredFormRules"></v-text-field>
            </v-col>

            <v-col cols="10">
              <v-text-field v-model="studentData.total" outlined color="primary" type="number" label="المجموع" :rules="studentRequiredFormRules"></v-text-field>
            </v-col>

            <v-col cols="10">
              <v-text-field v-model="studentData.attempt" outlined color="primary" label="الدور" :rules="studentRequiredFormRules"></v-text-field>
              <!-- <v-autocomplete
                v-model="studentData.attempt"
                outlined
                color="primary"
                label="الدور"
                :items="$store.state.attempts"
                item-text="name"
                item-value="name"
                item-color="primary"
                :rules="studentRequiredFormRules"
              ></v-autocomplete> -->
            </v-col>

            <v-col cols="10">
              <v-autocomplete
                v-model="studentData.acceptedTypeId"
                outlined
                color="primary"
                label="قناة القبول"
                :items="$store.state.acceptedTypes"
                item-text="acceptedName"
                item-value="idAcceptedType"
                item-color="primary"
                :rules="studentRequiredFormRules"
              ></v-autocomplete>
            </v-col>

            <v-col cols="10">
              <v-text-field v-model="studentData.gender" outlined color="primary" label="الجنس" :rules="studentRequiredFormRules"></v-text-field>
              <!-- <v-autocomplete
                v-model="studentData.gender"
                outlined
                color="primary"
                label="الجنس"
                :items="$store.state.genders"
                item-text="genderName"
                item-value="genderName"
                item-color="primary"
                :rules="studentRequiredFormRules"
              ></v-autocomplete> -->
            </v-col>

            <v-col cols="10">
              <v-autocomplete
                v-model="studentData.education"
                outlined
                color="primary"
                label="التربية"
                :items="$store.state.educations"
                item-text="name"
                item-value="name"
                item-color="primary"
                :rules="studentRequiredFormRules"
              ></v-autocomplete>
            </v-col>

            <v-col cols="10">
              <v-text-field v-model="studentData.schoolName" outlined color="primary" label="المدرسة" :rules="studentRequiredFormRules"></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="1" style="padding: 20px"> القسم 1 </v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="studentData.firstSectionName"
                outlined
                color="primary"
                label="القسم"
                :items="$store.state.sections"
                item-text="sectionName"
                item-value="sectionName"
                item-color="primary"
                :rules="studentRequiredFormRules"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="studentData.firstStudyType"
                outlined
                color="primary"
                label="نوع الدراسة"
                :items="$store.state.studyTypes"
                item-text="name"
                item-value="name"
                item-color="primary"
                :rules="studentRequiredFormRules"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-text-field v-model="studentData.firstUniversity" outlined color="primary" label="الكلية" :rules="studentRequiredFormRules"></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="1" style="padding: 20px"> القسم 2 </v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="studentData.secondSectionName"
                outlined
                color="primary"
                label="القسم"
                :items="$store.state.sections"
                item-text="sectionName"
                item-value="sectionName"
                item-color="primary"
                :rules="studentRequiredFormRules"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="studentData.secondStudyType"
                outlined
                color="primary"
                label="نوع الدراسة"
                :items="$store.state.studyTypes"
                item-text="name"
                item-value="name"
                item-color="primary"
                :rules="studentRequiredFormRules"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-text-field v-model="studentData.secondUniversity" outlined color="primary" label="الكلية" :rules="studentRequiredFormRules"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="1" style="padding: 20px"> القسم 3 </v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="studentData.thirdSectionName"
                outlined
                color="primary"
                label="القسم"
                :items="$store.state.sections"
                item-text="sectionName"
                item-value="sectionName"
                item-color="primary"
                :rules="studentRequiredFormRules"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="studentData.thirdStudyType"
                outlined
                color="primary"
                label="نوع الدراسة"
                :items="$store.state.studyTypes"
                item-text="name"
                item-value="name"
                item-color="primary"
                :rules="studentRequiredFormRules"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-text-field v-model="studentData.thirdUniversity" outlined color="primary" label="الكلية" :rules="studentRequiredFormRules"></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="1" style="padding: 20px"> القسم 4 </v-col>
            <v-col cols="4">
              <v-autocomplete
                v-model="studentData.fourthSectionName"
                outlined
                color="primary"
                label="القسم"
                :items="$store.state.sections"
                item-text="sectionName"
                item-value="sectionName"
                item-color="primary"
                :rules="studentRequiredFormRules"
              ></v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="studentData.fourthStudyType"
                outlined
                color="primary"
                label="نوع الدراسة"
                :items="$store.state.studyTypes"
                item-text="name"
                item-value="name"
                item-color="primary"
                :rules="studentRequiredFormRules"
              ></v-autocomplete>
            </v-col>
            <v-col cols="4">
              <v-text-field v-model="studentData.fourthUniversity" outlined color="primary" label="الكلية" :rules="studentRequiredFormRules"></v-text-field>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col cols="10">
            <v-text-field
              v-model="studentData.studentName"
              outlined
              disabled
              v-if="$store.state.userData.roleId == 61"
              color="primary"
              label="اسم الطالب الرباعي"
              :rules="studentRequiredFormRules"
            ></v-text-field>
          </v-col>
          <v-col cols="10">
            <v-textarea v-model="studentData.notes" outlined name="input-7-4" label="الملاحظات"></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col style="text-align: center">
            <v-btn tile color="success" @click="editStudent">
              <v-icon left> mdi-pencil </v-icon>
              تعديل
            </v-btn>
            <div style="margin: 0px 10px; display: inline-block"></div>
            <v-btn tile color="primary" @click="printPage()">
              <v-icon left> mdi-printer </v-icon>
              طباعة
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <div style="position: absolute; top: -200px">
      <VueBarcode :value="collegeNumber" displayValue="false" width="3" height="40"> c </VueBarcode>
    </div>
  </div>
</template>

<script>
// var JsBarcode = require("jsbarcode");
import VueBarcode from "vue-barcode";
import moment from "moment";
export default {
  components: {
    VueBarcode,
  },
  data() {
    return {
      overlay: false,
      collegeNumber: "1234",
      tab: null,
      search: "",
      showPrint: false,
      student: "",
      show: false,
      dobMenuModel: false,
      studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
      studentDob: moment(new Date()).format("YYYY-MM-DD"),
      dobActivePicker: null,
      studentData: null,
      barcodeWrapper: "",
    };
  },

  watch: {
    dobMenuModel(val) {
      val && setTimeout(() => (this.dobActivePicker = "YEAR"));
    },
  },
  created() {
    this.initialization();
  },
  methods: {
    initialization() {
      let self = this;
      self.totalStudents = Math.floor(Math.random() * 200);
      console.log(self.$store.state.years);
      self.sectionCode = Math.floor(Math.random() * 30);
    },
    calculateCollegeNumber() {
      let self = this;
      let acceptedYear = self.studyYear.slice(self.studyYear.length - 2, self.studyYear.length);
      let gender = self.gender == "ذكر" ? 0 : 1;
      let dob = self.studentDob.slice(2, 4);
      return `23${acceptedYear}${this.sectionCode}${dob}${gender}${self.totalStudents}`;
    },
    saveDob(date) {
      this.$refs.dobMenuRef.save(date);
    },
    editStudent() {
      let self = this;
      this.$http
        .put(`${this.$store.state.apiUrlApplication}/application/${self.studentData.idApplication}`, self.studentData, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res.data);
          this.$toasted.success("تم تعديل البيانات");
          this.$emit("reloadTask");
        })
        .catch((err) => {
          this.$toasted.error(" حدث خطأ في البيانات");
        });
    },
    printPage() {
      let self = this;
      let stylesHtml = "";
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
      }

      let image = require("../assets/logo.png");

      // Open the print window
      const WinPrint = window.open("", "", "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0");
      console.log(self.barcodeWrapper);
      var svgBarcode = new XMLSerializer();
      var str = svgBarcode.serializeToString(self.barcodeWrapper);

      WinPrint.document.write(`
      <!DOCTYPE html>
        <html dir="rtl">
          ${stylesHtml}
          <style>

          </style>
          <body>

            <div data-v-47373a88="" class="center-print" style="text-align: center">
              <div data-v-47373a88="" class="v-image v-responsive my-3 image-print theme--light" style="height: 200px">
                <div class="v-responsive__sizer" style="padding-bottom: 99.5851%"></div>
                <div class="v-image__image v-image__image--contain" style="background-image: url('http://localhost:8080/img/logo.b48c030d.png'); background-position: center center"></div>
                <div class="v-responsive__content" style="width: 241px"></div>
              </div>
              <h5 data-v-47373a88="">جامعة التراث</h5>
              <h5 data-v-47373a88="">نحافظ على القيم ونعد للمستقبل</h5>
              <h5 data-v-47373a88="">استمارة اجرائات التقديم والقبول الالكترونية للعام الدراسي 2022-2023</h5>
              ${str}
              <div style="text-align:center">
                <h5>رقم الاستمارة : ${self.studentData.applicationNumber}</h5>
              </div>
              <div data-v-47373a88="" class="center-print">
                <div data-v-47373a88="">
                  <h3 data-v-47373a88="" style="padding: 5px; text-align: center; background: rgb(2, 87, 74); border-radius: 25px; margin: 10px; color: rgb(255, 255, 255)">
                    المعلومات الشخصية والدراسية للطالب
                  </h3>
                </div>
                <div data-v-47373a88="" style="padding: 5px">
                  <div data-v-47373a88="" class="row">
                    <div data-v-47373a88="" class="col col-2" style="padding-right: 50px; text-align: right"><span data-v-47373a88="">كود الطالب</span></div>
                    <div data-v-47373a88="" class="col col-4" style="text-align: right"><span data-v-47373a88="">${self.studentData.collegeNumber}</span></div>
                    <div data-v-47373a88="" class="col col-2"><span data-v-47373a88="">الاسم الرباعي</span></div>
                    <div data-v-47373a88="" class="col col-4"><span data-v-47373a88="" class="copy">${self.studentData.studentName}</span></div>
                  </div>
                </div>
                <hr data-v-47373a88="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="width: 95%; margin: auto" />
                <div data-v-47373a88="" style="padding: 5px">
                  <div data-v-47373a88="" class="row">
                    <div data-v-47373a88="" class="col col-2" style="padding-right: 50px; text-align: right"><span data-v-47373a88="">اسم الام</span></div>
                    <div data-v-47373a88="" class="col col-4" style="text-align: right"><span data-v-47373a88="">${self.studentData.motherName}</span></div>
                    <div data-v-47373a88="" class="col col-2"><span data-v-47373a88="">رقم الهاتف</span></div>
                    <div data-v-47373a88="" class="col col-4" style="text-align: right"><span data-v-47373a88="">${self.studentData.studentPhone}</span></div>
                  </div>
                </div>
                <hr data-v-47373a88="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="width: 95%; margin: auto" />
                <div data-v-47373a88="" style="padding: 5px">
                  <div data-v-47373a88="" class="row">
                    <div data-v-47373a88="" class="col col-2" style="padding-right: 50px; text-align: right"><span data-v-47373a88="">الجنس</span></div>
                    <div data-v-47373a88="" class="col col-4" style="text-align: right"><span data-v-47373a88="">${self.studentData.gender}</span></div>
                    <div data-v-47373a88="" class="col col-2"><span data-v-47373a88="">التولد</span></div>
                    <div data-v-47373a88="" class="col col-4" style="text-align: right"><span data-v-47373a88="">${self.studentData.dob}</span></div>
                  </div>
                </div>
                <hr data-v-47373a88="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="width: 95%; margin: auto" />
                <div data-v-47373a88="" style="padding: 5px">
                  <div data-v-47373a88="" class="row">
                    <div data-v-47373a88="" class="col col-2" style="padding-right: 50px; text-align: right"><span data-v-47373a88="">الرقم الامتحاني</span></div>
                    <div data-v-47373a88="" class="col col-4" style="text-align: right"><span data-v-47373a88="" class="copy">${self.studentData.examNumber}</span></div>
                    <div data-v-47373a88="" class="col col-2"><span data-v-47373a88="">الرقم السري</span></div>
                    <div data-v-47373a88="" class="col col-4" style="text-align: right"><span data-v-47373a88="">${self.studentData.secretNumber}</span></div>
                  </div>
                </div>
                <hr data-v-47373a88="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="width: 95%; margin: auto" />
                <div data-v-47373a88="" style="padding: 5px">
                  <div data-v-47373a88="" class="row">
                    <div data-v-47373a88="" class="col col-2" style="padding-right: 50px; text-align: right"><span data-v-47373a88="">التربية</span></div>
                    <div data-v-47373a88="" class="col col-4" style="text-align: right"><span data-v-47373a88="">${self.studentData.education}</span></div>
                    <div data-v-47373a88="" class="col col-2"><span data-v-47373a88="">اسم المدرسة</span></div>
                    <div data-v-47373a88="" class="col col-4" style="text-align: right"><span data-v-47373a88="">${self.studentData.schoolName}</span></div>
                  </div>
                </div>
                <hr data-v-47373a88="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="width: 95%; margin: auto" />
                <div data-v-47373a88="" style="padding: 5px">
                  <div data-v-47373a88="" class="row">
                    <div data-v-47373a88="" class="col col-2" style="padding-right: 50px; text-align: right"><span data-v-47373a88="">هوية الاحوال</span></div>
                    <div data-v-47373a88="" class="col col-2"><span data-v-47373a88="">${self.studentData.nationalNumber}</span></div>
                    <div data-v-47373a88="" class="col col-2"><span data-v-47373a88="">المعدل</span></div>
                    <div data-v-47373a88="" class="col col-2"><span data-v-47373a88="">${self.studentData.average}</span></div>
                    <div data-v-47373a88="" class="col col-2"><span data-v-47373a88="">المجموع</span></div>
                    <div data-v-47373a88="" class="col col-2"><span data-v-47373a88="">${self.studentData.total}</span></div>
                  </div>
                </div>
                <hr data-v-47373a88="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="width: 95%; margin: auto" />
                <div data-v-47373a88="" style="padding: 5px">
                  <div data-v-47373a88="" class="row">
                    <div data-v-47373a88="" class="col col-2" style="padding-right: 50px; text-align: right"><span data-v-47373a88="">الدور</span></div>
                    <div data-v-47373a88="" class="col col-2"><span data-v-47373a88="">${self.studentData.attempt}</span></div>
                    <div data-v-47373a88="" class="col col-2"><span data-v-47373a88="">الفرع</span></div>
                    <div data-v-47373a88="" class="col col-2"><span data-v-47373a88="">${self.studentData.categoryName}</span></div>
                    <div data-v-47373a88="" class="col col-2"><span data-v-47373a88="">سنة التخرج</span></div>
                    <div data-v-47373a88="" class="col col-2"><span data-v-47373a88="">${self.studentData.graduationYear}</span></div>
                  </div>
                </div>
                <div data-v-47373a88="" class="center-print">
                  <h3 data-v-47373a88="" style="padding: 5px; text-align: center; background: rgb(2, 87, 74); border-radius: 25px; margin: 10px; color: rgb(255, 255, 255)">الاقسام المرشحة من قبل الطالب</h3>
                </div>
                <div data-v-47373a88="" style="padding: 5px">
                  <div data-v-47373a88="" class="row">
                    <div data-v-47373a88="" class="col col-3" style="padding-right: 50px; text-align: right"><span data-v-47373a88="">الترشيح الاول</span></div>
                    <div data-v-47373a88="" class="col col-3"><span data-v-47373a88="">${self.studentData.firstSectionName}</span></div>
                    <div data-v-47373a88="" class="col col-3"><span data-v-47373a88=""> ${self.studentData.firstStudyType}</span></div>
                    <div data-v-47373a88="" class="col col-3"><span data-v-47373a88="">${self.studentData.firstUniversity}</span></div>
                  </div>
                </div>
                <hr data-v-47373a88="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="width: 95%; margin: auto" />
                <div data-v-47373a88="" style="padding: 5px">
                  <div data-v-47373a88="" class="row">
                    <div data-v-47373a88="" class="col col-3" style="padding-right: 50px; text-align: right"><span data-v-47373a88="">الترشيح الثاني</span></div>
                    <div data-v-47373a88="" class="col col-3"><span data-v-47373a88="">${self.studentData.secondSectionName == "none" ? " " : self.studentData.secondSectionName}</span></div>
                    <div data-v-47373a88="" class="col col-3"><span data-v-47373a88="">${self.studentData.secondStudyType == "none" ? " " : self.studentData.secondStudyType}</span></div>
                    <div data-v-47373a88="" class="col col-3"><span data-v-47373a88="">${self.studentData.secondUniversity == "none" ? " " : self.studentData.secondUniversity}</span></div>
                  </div>
                </div>
                <hr data-v-47373a88="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="width: 95%; margin: auto" />
                <div data-v-47373a88="" style="padding: 5px">
                  <div data-v-47373a88="" class="row">
                    <div data-v-47373a88="" class="col col-3" style="padding-right: 50px; text-align: right"><span data-v-47373a88="">الترشيح الثالث</span></div>
                    <div data-v-47373a88="" class="col col-3"><span data-v-47373a88="">${self.studentData.thirdSectionName == "none" ? " " : self.studentData.thirdSectionName}</span></div>
                    <div data-v-47373a88="" class="col col-3"><span data-v-47373a88="">${self.studentData.thirdStudyType == "none" ? " " : self.studentData.thirdStudyType}</span></div>
                    <div data-v-47373a88="" class="col col-3"><span data-v-47373a88="">${self.studentData.thirdUniversity == "none" ? " " : self.studentData.thirdUniversity}</span></div>
                  </div>
                </div>
                <hr data-v-47373a88="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="width: 95%; margin: auto" />
                <div data-v-47373a88="" style="padding: 5px">
                  <div data-v-47373a88="" class="row">
                    <div data-v-47373a88="" class="col col-3" style="padding-right: 50px; text-align: right"><span data-v-47373a88="">الترشيح الرابع</span></div>
                    <div data-v-47373a88="" class="col col-3"><span data-v-47373a88="">${self.studentData.fourthSectionName == "none" ? " " : self.studentData.fourthSectionName}</span></div>
                    <div data-v-47373a88="" class="col col-3"><span data-v-47373a88="">${self.studentData.fourthStudyType == "none" ? " " : self.studentData.fourthStudyType}</span></div>
                    <div data-v-47373a88="" class="col col-3"><span data-v-47373a88="">${self.studentData.fourthUniversity == "none" ? " " : self.studentData.fourthUniversity}</span></div>
                  </div>
                </div>
                <div data-v-47373a88="" class="center-print">
                  <h3 data-v-47373a88="" style="padding: 5px; text-align: center; background: rgb(2, 87, 74); border-radius: 25px; margin: 10px; color: rgb(255, 255, 255)">معلومات خاصة بلجنة القبول</h3>
                </div>
                <div data-v-47373a88="" style="padding: 5px">
                  <div data-v-47373a88="" class="row">
                    <div data-v-47373a88="" class="col col-3" style="padding-right: 50px; text-align: right"><span data-v-47373a88="">قناة القبول</span></div>
                    <div data-v-47373a88="" class="col col-3"><span data-v-47373a88="">${self.studentData.acceptedName}</span></div>
                    <div data-v-47373a88="" class="col col-3"><span data-v-47373a88="">الملاحظات</span></div>
                    <div data-v-47373a88="" class="col col-3"><span data-v-47373a88="">${self.studentData.notes}</span></div>
                  </div>
                </div>
              </div>
            </div>
          </body>
        </html>
      `);

      WinPrint.document.close();
      WinPrint.focus();
      setTimeout(() => {
        WinPrint.print();
        WinPrint.close();
      }, 500);
    },
    onlyLettersAndNumbers(str) {
      return /[\u0600-\u06FF\u0750-\u077F]/.test(str);
    },
    searchData() {
      let self = this;

      self.$http
        .get(`${self.$store.state.apiUrlApplication}/applicationSearch/${self.student}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          self.studentData = res.data;
          self.studentData.gender = self.studentData.gender == 1 ? "ذكر" : "انثى";
          console.log(res.data);

          self.collegeNumber = res.data.collegeNumber;

          let barcodeWrapper = document.querySelector(".vue-barcode-element");
          console.log(barcodeWrapper);
          self.barcodeWrapper = barcodeWrapper;

          self.show = true;
        })
        .catch((err) => {
          this.$toasted.error("رقم الاستمارة او اسم الطالب غير موجود الرجاء التأكد من المعلومات");
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  position: relative;
}
</style>
