<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-container class="container" fluid>
      <v-alert type="warning">
        <h4>اكتب رقم الاستمارة ومن ثم اضغط على ENTER لاظهار اسم الطالب وفي حال عدم معرفة رقم الاستمارة الرجاء البحث عنها من القائمة الجانبية في البحث داخل الاستمارات</h4>
      </v-alert>
    </v-container>

    <v-container class="container" fluid>
      <v-card elevation="6" style="padding: 20px">
        <v-row>
          <v-col cols="10">
            <v-text-field :value="calculateCollegeNumber()" outlined color="primary" label="الرقم الاحصائي" disabled></v-text-field>
          </v-col>
          <v-col cols="10">
            <v-text-field v-model="formNumber" @keyup.enter="searchForm()" outlined color="primary" label="رقم الاستمارة"></v-text-field>
          </v-col>
          <v-col cols="10">
            <v-text-field v-model="studentName" outlined color="primary" label="اسم الطالب الرباعي" disabled></v-text-field>
          </v-col>
          <v-col cols="10">
            <v-text-field v-model="studentPhone" outlined color="primary" label="رقم الهاتف"></v-text-field>
          </v-col>
          <v-col cols="10">
            <v-text-field v-model="motherName" outlined color="primary" label="اسم الام الثلاثي"></v-text-field>
          </v-col>
          <v-col cols="10">
            <v-text-field v-model="examNumber" outlined color="primary" label="الرقم الامتحاني"></v-text-field>
          </v-col>
          <v-col cols="10">
            <v-text-field v-model="secretNumber" outlined color="primary" label="الرقم السري"></v-text-field>
          </v-col>
          <v-col cols="10">
            <v-text-field v-model="studentDob" outlined color="primary" label="تاريخ الميلاد"></v-text-field>
          </v-col>

          <v-col cols="10">
            <v-text-field v-model="nationalNumber" outlined color="primary" label="رقم هوية الاحوال المدنية"></v-text-field>
          </v-col>

          <v-col cols="10">
            <v-autocomplete
              v-model="categoryId"
              outlined
              color="primary"
              label="الفرع"
              :items="$store.state.categories"
              item-text="categoryName"
              item-value="idCategory"
              item-color="primary"
            ></v-autocomplete>
          </v-col>

          <v-col cols="10">
            <v-autocomplete v-model="studyYear" outlined color="primary" label="سنة التخرج" :items="$store.state.years" item-text="year" item-value="year" item-color="primary"></v-autocomplete>
          </v-col>

          <v-col cols="10">
            <v-text-field v-model="average" outlined color="primary" type="number" label="المعدل"></v-text-field>
          </v-col>

          <v-col cols="10">
            <v-text-field v-model="total" outlined color="primary" type="number" label="المجموع"></v-text-field>
          </v-col>

          <v-col cols="10">
            <v-autocomplete v-model="attempt" outlined color="primary" label="الدور" :items="$store.state.attempts" item-text="name" item-value="name" item-color="primary"></v-autocomplete>
          </v-col>

          <v-col cols="10">
            <v-autocomplete
              v-model="acceptedTypeId"
              outlined
              color="primary"
              label="قناة القبول"
              :items="$store.state.acceptedTypes"
              item-text="acceptedName"
              item-value="idAcceptedType"
              item-color="primary"
            ></v-autocomplete>
          </v-col>

          <v-col cols="10">
            <v-autocomplete v-model="gender" outlined color="primary" label="الجنس" :items="$store.state.genders" item-text="name" item-value="name" item-color="primary"></v-autocomplete>
          </v-col>

          <v-col cols="10">
            <v-autocomplete v-model="education" outlined color="primary" label="التربية" :items="$store.state.educations" item-text="name" item-value="name" item-color="primary"></v-autocomplete>
          </v-col>

          <v-col cols="10">
            <v-text-field v-model="schoolName" outlined color="primary" label="المدرسة"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="1" style="padding: 20px"> القسم 1 </v-col>
          <v-col cols="4">
            <v-autocomplete
              v-model="sectionName"
              outlined
              color="primary"
              label="القسم"
              :items="$store.state.sections"
              item-text="sectionName"
              item-value="sectionName"
              item-color="primary"
            ></v-autocomplete>
          </v-col>
          <v-col cols="2">
            <v-autocomplete v-model="studyType" outlined color="primary" label="نوع الدراسة" :items="$store.state.studyTypes" item-text="name" item-value="name" item-color="primary"></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="universityName" outlined color="primary" label="الكلية"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="1" style="padding: 20px"> القسم 2 </v-col>
          <v-col cols="4">
            <v-autocomplete
              v-model="secondSectionName"
              outlined
              color="primary"
              label="القسم"
              :items="$store.state.sections"
              item-text="sectionName"
              item-value="sectionName"
              item-color="primary"
            ></v-autocomplete>
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              v-model="secondStudyType"
              outlined
              color="primary"
              label="نوع الدراسة"
              :items="$store.state.studyTypes"
              item-text="name"
              item-value="name"
              item-color="primary"
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="secondUniversityName" outlined color="primary" label="الكلية"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="1" style="padding: 20px"> القسم 3 </v-col>
          <v-col cols="4">
            <v-autocomplete
              v-model="thirdSectionName"
              outlined
              color="primary"
              label="القسم"
              :items="$store.state.sections"
              item-text="sectionName"
              item-value="sectionName"
              item-color="primary"
            ></v-autocomplete>
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              v-model="thirdStudyType"
              outlined
              color="primary"
              label="نوع الدراسة"
              :items="$store.state.studyTypes"
              item-text="name"
              item-value="name"
              item-color="primary"
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="thirdUniversityName" outlined color="primary" label="الكلية"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="1" style="padding: 20px"> القسم 4 </v-col>
          <v-col cols="4">
            <v-autocomplete
              v-model="fourthSectionName"
              outlined
              color="primary"
              label="القسم"
              :items="$store.state.sections"
              item-text="sectionName"
              item-value="sectionName"
              item-color="primary"
            ></v-autocomplete>
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              v-model="fourthStudyType"
              outlined
              color="primary"
              label="نوع الدراسة"
              :items="$store.state.studyTypes"
              item-text="name"
              item-value="name"
              item-color="primary"
            ></v-autocomplete>
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="fourthUniversityName" outlined color="primary" label="الكلية"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="10">
            <v-textarea v-model="notes" outlined name="input-7-4" label="الملاحظات"></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col style="text-align: center">
            <v-btn tile color="success" @click="saveStudent">
              <v-icon left> mdi-desk </v-icon>
              حفظ
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <div style="position: absolute; top: -200px">
      <VueBarcode :value="collegeNumber" displayValue="false" width="3" height="40"> c </VueBarcode>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import VueBarcode from "vue-barcode";
export default {
  components: {
    VueBarcode,
  },
  data() {
    return {
      overlay: false,
      tab: null,
      collegeNumber: "",
      dobMenuModel: false,
      studentDob: moment(new Date()).format("YYYY-MM-DD"),
      dobActivePicker: null,
      studentName: null,
      formNumber: "",
      studentPhone: "",
      motherName: "",
      examNumber: "",
      secretNumber: "",
      nationalNumber: "",
      categoryId: "",
      studyYear: "",
      average: "",
      total: "",
      attempt: "",
      acceptedTypeId: "",
      gender: "",
      education: "",
      schoolName: "",
      sectionName: "",
      studyType: "",
      universityName: "كلية التراث",
      secondSectionName: "",
      secondStudyType: "",
      secondUniversityName: "",
      thirdSectionName: "",
      thirdStudyType: "",
      thirdUniversityName: "",
      fourthSectionName: "",
      fourthStudyType: "",
      fourthUniversityName: "",
      notes: "بلا",
      sectionCode: 0,
      totalStudents: 0,
      barcodeWrapper: "",
    };
  },

  watch: {
    dobMenuModel(val) {
      val && setTimeout(() => (this.dobActivePicker = "YEAR"));
    },
  },
  created() {
    this.initialization();
  },
  methods: {
    initialization() {
      let self = this;
      self.totalStudents = Math.floor(Math.random() * 500);
      self.sectionCode = Math.floor(Math.random() * 50);
      self.$http.get(`${self.$store.state.apiUrlApplication}/getLast`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }).then((res) => {
        console.log(res.data);
      });
    },
    searchForm() {
      let self = this;
      self.$http
        .get(`${self.$store.state.apiUrlFees}/searchByNumber/${self.formNumber}`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } })
        .then((res) => {
          self.studentName = res.data.studentName;
          self.applicationNumber = res.data.formNumber;
        })
        .catch((e) => console.log(e));
    },
    saveStudent() {
      let self = this;
      self.overlay = true;
      if (
        self.studentName &&
        self.studentPhone &&
        self.motherName &&
        self.examNumber &&
        self.secretNumber &&
        self.nationalNumber &&
        self.categoryId &&
        self.studyYear &&
        self.attempt &&
        self.acceptedTypeId &&
        self.gender &&
        self.education &&
        self.schoolName &&
        self.sectionName &&
        self.studyType &&
        self.universityName &&
        self.notes
      ) {
        self.$http
          .get(`${self.$store.state.apiUrlApplication}/getLast`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } })
          .then((res) => {
            console.log(res.data);

            let data = {
              applicationNumber: self.applicationNumber,
              studentName: self.studentName,
              studentPhone: self.studentPhone,
              collegeNumber: self.calculateCollegeNumber(),
              motherName: self.motherName,
              examNumber: self.examNumber,
              secretNumber: self.secretNumber,
              dob: self.studentDob,
              nationalNumber: self.nationalNumber,
              categoryId: self.categoryId,
              graduationYear: self.studyYear,
              average: self.average ? self.average : 0,
              total: self.total ? self.total : 0,
              attempt: self.attempt,
              password: self.categoryId > 5 ? "61126113" : "19881988",
              acceptedTypeId: self.acceptedTypeId,
              gender: self.gender,
              notes: self.notes,
              education: self.education,
              schoolName: self.schoolName,
              createdBy: self.$store.state.userData.idEmployee,
              firstSectionName: self.sectionName ? self.sectionName : "none",
              firstStudyType: self.studyType ? self.studyType : "none",
              firstUniversity: self.universityName ? self.universityName : "none",
              secondUniversity: self.secondUniversityName ? self.secondUniversityName : "none",
              secondStudyType: self.secondStudyType ? self.secondStudyType : "none",
              secondSectionName: self.secondSectionName ? self.secondSectionName : "none",
              thirdUniversity: self.thirdUniversityName ? self.thirdUniversityName : "none",
              thirdStudyType: self.thirdStudyType ? self.thirdStudyType : "none",
              thirdSectionName: self.thirdSectionName ? self.thirdSectionName : "none",
              fourthUniversity: self.fourthUniversityName ? self.fourthUniversityName : "none",
              fourthSectionName: self.fourthSectionName ? self.fourthSectionName : "none",
              fourthStudyType: self.fourthStudyType ? self.fourthStudyType : "none",
            };
            self.$http
              .post(`${self.$store.state.apiUrlApplication}/addApplication`, data, { headers: { Authorization: `Bearer ${self.$store.state.user}` } })
              .then((resStudent) => {
                console.log(resStudent.data.id);

                self.formNumber = "";
                self.studentPhone = "";
                self.motherName = "";
                self.examNumber = "";
                self.secretNumber = "";
                self.nationalNumber = "";
                self.categoryId = "";
                self.studyYear = "";
                self.average = "";
                self.total = "";
                self.attempt = "";
                self.acceptedTypeId = "";
                self.gender = "";
                self.education = "";
                self.schoolName = "";
                self.sectionName = "";
                self.studyType = "";
                self.universityName = "كلية التراث";
                self.secondSectionName = "";
                self.secondStudyType = "";
                self.secondUniversityName = "";
                self.thirdSectionName = "";
                self.thirdStudyType = "";
                self.thirdUniversityName = "";
                self.fourthSectionName = "";
                self.fourthStudyType = "";
                self.fourthUniversityName = "";
                self.notes = "بلا";

                let barcodeWrapper = document.querySelector(".vue-barcode-element");
                console.log(barcodeWrapper);
                self.barcodeWrapper = barcodeWrapper;

                self.initialization();
                self.collegeNumber = resStudent.data.collegeNumber;
                setTimeout(() => {
                  self.printPage(resStudent.data);
                }, 500);
                self.overlay = false;
                this.$toasted.success("تم حفظ البيانات");
              })
              .catch((e) => {
                console.log(e);
                self.overlay = false;
                this.$toasted.error("حدث خطأ الرجاء التأكد من المعلومات");
              });
          })
          .catch((e) => {
            console.log(e);
            self.overlay = false;
            this.$toasted.error("حدث خطأ الرجاء التأكد من المعلومات");
          });
      } else {
        self.overlay = false;
        this.$toasted.error(" الرجاء ملئ الحقول المطلوبة");
      }
    },
    printPage(studentData) {
      let self = this;
      let stylesHtml = "";
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
      }

      let image = require("../assets/logo.png");

      // Open the print window
      const WinPrint = window.open("", "", "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0");
      console.log(self.barcodeWrapper);
      var svgBarcode = new XMLSerializer();
      var str = svgBarcode.serializeToString(self.barcodeWrapper);

      WinPrint.document.write(`
      <!DOCTYPE html>
        <html dir="rtl">
          ${stylesHtml}
          <style>

          </style>
          <body>

            <div data-v-47373a88="" class="center-print" style="text-align: center">
              <div data-v-47373a88="" class="v-image v-responsive my-3 image-print theme--light" style="height: 200px">
                <div class="v-responsive__sizer" style="padding-bottom: 99.5851%"></div>
                <div class="v-image__image v-image__image--contain" style="background-image: url('http://localhost:8080/img/logo.b48c030d.png'); background-position: center center"></div>
                <div class="v-responsive__content" style="width: 241px"></div>
              </div>
              <h5 data-v-47373a88="">جامعة التراث</h5>
              <h5 data-v-47373a88="">نحافظ على القيم ونعد للمستقبل</h5>
              <h5 data-v-47373a88="">استمارة اجرائات التقديم والقبول الالكترونية للعام الدراسي 2022-2023</h5>
              ${str}
              <div style="text-align:center">
                <h5>رقم الاستمارة : ${studentData.applicationNumber}</h5>
              </div>
              <div data-v-47373a88="" class="center-print">
                <div data-v-47373a88="">
                  <h3 data-v-47373a88="" style="padding: 5px; text-align: center; background: rgb(2, 87, 74); border-radius: 25px; margin: 10px; color: rgb(255, 255, 255)">
                    المعلومات الشخصية والدراسية للطالب
                  </h3>
                </div>
                <div data-v-47373a88="" style="padding: 5px">
                  <div data-v-47373a88="" class="row">
                    <div data-v-47373a88="" class="col col-2" style="padding-right: 50px; text-align: right"><span data-v-47373a88="">كود الطالب</span></div>
                    <div data-v-47373a88="" class="col col-4" style="text-align: right"><span data-v-47373a88="">${studentData.collegeNumber}</span></div>
                    <div data-v-47373a88="" class="col col-2"><span data-v-47373a88="">الاسم الرباعي</span></div>
                    <div data-v-47373a88="" class="col col-4"><span data-v-47373a88="" class="copy">${studentData.studentName}</span></div>
                  </div>
                </div>
                <hr data-v-47373a88="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="width: 95%; margin: auto" />
                <div data-v-47373a88="" style="padding: 5px">
                  <div data-v-47373a88="" class="row">
                    <div data-v-47373a88="" class="col col-2" style="padding-right: 50px; text-align: right"><span data-v-47373a88="">اسم الام</span></div>
                    <div data-v-47373a88="" class="col col-4" style="text-align: right"><span data-v-47373a88="">${studentData.motherName}</span></div>
                    <div data-v-47373a88="" class="col col-2"><span data-v-47373a88="">رقم الهاتف</span></div>
                    <div data-v-47373a88="" class="col col-4" style="text-align: right"><span data-v-47373a88="">${studentData.studentPhone}</span></div>
                  </div>
                </div>
                <hr data-v-47373a88="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="width: 95%; margin: auto" />
                <div data-v-47373a88="" style="padding: 5px">
                  <div data-v-47373a88="" class="row">
                    <div data-v-47373a88="" class="col col-2" style="padding-right: 50px; text-align: right"><span data-v-47373a88="">الجنس</span></div>
                    <div data-v-47373a88="" class="col col-4" style="text-align: right"><span data-v-47373a88="">${studentData.gender}</span></div>
                    <div data-v-47373a88="" class="col col-2"><span data-v-47373a88="">التولد</span></div>
                    <div data-v-47373a88="" class="col col-4" style="text-align: right"><span data-v-47373a88="">${studentData.dob}</span></div>
                  </div>
                </div>
                <hr data-v-47373a88="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="width: 95%; margin: auto" />
                <div data-v-47373a88="" style="padding: 5px">
                  <div data-v-47373a88="" class="row">
                    <div data-v-47373a88="" class="col col-2" style="padding-right: 50px; text-align: right"><span data-v-47373a88="">الرقم الامتحاني</span></div>
                    <div data-v-47373a88="" class="col col-4" style="text-align: right"><span data-v-47373a88="" class="copy">${studentData.examNumber}</span></div>
                    <div data-v-47373a88="" class="col col-2"><span data-v-47373a88="">الرقم السري</span></div>
                    <div data-v-47373a88="" class="col col-4" style="text-align: right"><span data-v-47373a88="">${studentData.secretNumber}</span></div>
                  </div>
                </div>
                <hr data-v-47373a88="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="width: 95%; margin: auto" />
                <div data-v-47373a88="" style="padding: 5px">
                  <div data-v-47373a88="" class="row">
                    <div data-v-47373a88="" class="col col-2" style="padding-right: 50px; text-align: right"><span data-v-47373a88="">التربية</span></div>
                    <div data-v-47373a88="" class="col col-4" style="text-align: right"><span data-v-47373a88="">${studentData.education}</span></div>
                    <div data-v-47373a88="" class="col col-2"><span data-v-47373a88="">اسم المدرسة</span></div>
                    <div data-v-47373a88="" class="col col-4" style="text-align: right"><span data-v-47373a88="">${studentData.schoolName}</span></div>
                  </div>
                </div>
                <hr data-v-47373a88="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="width: 95%; margin: auto" />
                <div data-v-47373a88="" style="padding: 5px">
                  <div data-v-47373a88="" class="row">
                    <div data-v-47373a88="" class="col col-2" style="padding-right: 50px; text-align: right"><span data-v-47373a88="">هوية الاحوال</span></div>
                    <div data-v-47373a88="" class="col col-2"><span data-v-47373a88="">${studentData.nationalNumber}</span></div>
                    <div data-v-47373a88="" class="col col-2"><span data-v-47373a88="">المعدل</span></div>
                    <div data-v-47373a88="" class="col col-2"><span data-v-47373a88="">${studentData.average}</span></div>
                    <div data-v-47373a88="" class="col col-2"><span data-v-47373a88="">المجموع</span></div>
                    <div data-v-47373a88="" class="col col-2"><span data-v-47373a88="">${studentData.total}</span></div>
                  </div>
                </div>
                <hr data-v-47373a88="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="width: 95%; margin: auto" />
                <div data-v-47373a88="" style="padding: 5px">
                  <div data-v-47373a88="" class="row">
                    <div data-v-47373a88="" class="col col-2" style="padding-right: 50px; text-align: right"><span data-v-47373a88="">الدور</span></div>
                    <div data-v-47373a88="" class="col col-2"><span data-v-47373a88="">${studentData.attempt}</span></div>
                    <div data-v-47373a88="" class="col col-2"><span data-v-47373a88="">الفرع</span></div>
                    <div data-v-47373a88="" class="col col-2"><span data-v-47373a88="">${studentData.categoryName}</span></div>
                    <div data-v-47373a88="" class="col col-2"><span data-v-47373a88="">سنة التخرج</span></div>
                    <div data-v-47373a88="" class="col col-2"><span data-v-47373a88="">${studentData.graduationYear}</span></div>
                  </div>
                </div>
                <div data-v-47373a88="" class="center-print">
                  <h3 data-v-47373a88="" style="padding: 5px; text-align: center; background: rgb(2, 87, 74); border-radius: 25px; margin: 10px; color: rgb(255, 255, 255)">الاقسام المرشحة من قبل الطالب</h3>
                </div>
                <div data-v-47373a88="" style="padding: 5px">
                  <div data-v-47373a88="" class="row">
                    <div data-v-47373a88="" class="col col-3" style="padding-right: 50px; text-align: right"><span data-v-47373a88="">الترشيح الاول</span></div>
                    <div data-v-47373a88="" class="col col-3"><span data-v-47373a88="">${studentData.firstSectionName}</span></div>
                    <div data-v-47373a88="" class="col col-3"><span data-v-47373a88=""> ${studentData.firstStudyType}</span></div>
                    <div data-v-47373a88="" class="col col-3"><span data-v-47373a88="">${studentData.firstUniversity}</span></div>
                  </div>
                </div>
                <hr data-v-47373a88="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="width: 95%; margin: auto" />
                <div data-v-47373a88="" style="padding: 5px">
                  <div data-v-47373a88="" class="row">
                    <div data-v-47373a88="" class="col col-3" style="padding-right: 50px; text-align: right"><span data-v-47373a88="">الترشيح الثاني</span></div>
                    <div data-v-47373a88="" class="col col-3"><span data-v-47373a88="">${studentData.secondSectionName == "none" ? " " : studentData.secondSectionName}</span></div>
                    <div data-v-47373a88="" class="col col-3"><span data-v-47373a88="">${studentData.secondStudyType == "none" ? " " : studentData.secondStudyType}</span></div>
                    <div data-v-47373a88="" class="col col-3"><span data-v-47373a88="">${studentData.secondUniversity == "none" ? " " : studentData.secondUniversity}</span></div>
                  </div>
                </div>
                <hr data-v-47373a88="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="width: 95%; margin: auto" />
                <div data-v-47373a88="" style="padding: 5px">
                  <div data-v-47373a88="" class="row">
                    <div data-v-47373a88="" class="col col-3" style="padding-right: 50px; text-align: right"><span data-v-47373a88="">الترشيح الثالث</span></div>
                    <div data-v-47373a88="" class="col col-3"><span data-v-47373a88="">${studentData.thirdSectionName == "none" ? " " : studentData.thirdSectionName}</span></div>
                    <div data-v-47373a88="" class="col col-3"><span data-v-47373a88="">${studentData.thirdStudyType == "none" ? " " : studentData.thirdStudyType}</span></div>
                    <div data-v-47373a88="" class="col col-3"><span data-v-47373a88="">${studentData.thirdUniversity == "none" ? " " : studentData.thirdUniversity}</span></div>
                  </div>
                </div>
                <hr data-v-47373a88="" role="separator" aria-orientation="horizontal" class="v-divider theme--light" style="width: 95%; margin: auto" />
                <div data-v-47373a88="" style="padding: 5px">
                  <div data-v-47373a88="" class="row">
                    <div data-v-47373a88="" class="col col-3" style="padding-right: 50px; text-align: right"><span data-v-47373a88="">الترشيح الرابع</span></div>
                    <div data-v-47373a88="" class="col col-3"><span data-v-47373a88="">${studentData.fourthSectionName == "none" ? " " : studentData.fourthSectionName}</span></div>
                    <div data-v-47373a88="" class="col col-3"><span data-v-47373a88="">${studentData.fourthStudyType == "none" ? " " : studentData.fourthStudyType}</span></div>
                    <div data-v-47373a88="" class="col col-3"><span data-v-47373a88="">${studentData.fourthUniversity == "none" ? " " : studentData.fourthUniversity}</span></div>
                  </div>
                </div>
                <div data-v-47373a88="" class="center-print">
                  <h3 data-v-47373a88="" style="padding: 5px; text-align: center; background: rgb(2, 87, 74); border-radius: 25px; margin: 10px; color: rgb(255, 255, 255)">معلومات خاصة بلجنة القبول</h3>
                </div>
                <div data-v-47373a88="" style="padding: 5px">
                  <div data-v-47373a88="" class="row">
                    <div data-v-47373a88="" class="col col-3" style="padding-right: 50px; text-align: right"><span data-v-47373a88="">قناة القبول</span></div>
                    <div data-v-47373a88="" class="col col-3"><span data-v-47373a88="">${studentData.acceptedName}</span></div>
                    <div data-v-47373a88="" class="col col-3"><span data-v-47373a88="">الملاحظات</span></div>
                    <div data-v-47373a88="" class="col col-3"><span data-v-47373a88="">${studentData.notes}</span></div>
                  </div>
                </div>
              </div>
            </div>
          </body>
        </html>
      `);

      WinPrint.document.close();
      WinPrint.focus();
      setTimeout(() => {
        WinPrint.print();
        WinPrint.close();
      }, 500);
    },
    calculateCollegeNumber() {
      let self = this;
      let acceptedYear = self.studyYear.slice(self.studyYear.length - 2, self.studyYear.length);
      let gender = self.gender == "ذكر" ? 0 : 1;
      let dob = self.studentDob.slice(2, 4);
      let str = `23${acceptedYear}${this.sectionCode}${dob}${gender}${self.totalStudents}`;
      return str.replace(/\D/g, "");
    },
    saveDob(date) {
      this.$refs.dobMenuRef.save(date);
    },
  },
};
</script>

<style lang="scss" scoped></style>
