<template>
  <div>
    <canvas id="section-chart"></canvas>
  </div>
</template>

<script>
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale
);

export default {
  name: "SectionChart",
  props: {
    labels: Array,
    data: Array,
  },
  data: () => ({}),

  mounted() {
    const ctx = document.getElementById("section-chart");
    let data = {
      type: "bar",
      data: {
        labels: this.labels,
        datasets: [
          {
            label: "الكتب",
            data: this.data,
            backgroundColor: "#006a71",
            borderColor: "#006a71",
            borderWidth: 3,
          },
        ],
      },
      options: {
        responsive: true,
        lineTension: 1,
      },
    };
    new Chart(ctx, data);
  },
};
</script>
