<template>
  <div>
    <v-container class="container not-printable" fluid>
      <v-card elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="10">
            <v-text-field v-model="student" @keyup.enter="searchData()" outlined color="primary" label="رقم الاستمارة او اسم الطالب" :rules="studentRequiredFormRules"></v-text-field>
          </v-col>
          <v-col cols="2" style="padding: 20px">
            <v-btn tile color="primary" @click="searchData()">
              <v-icon left> mdi-text-box-search </v-icon>
              بحث
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container class="container not-printable" fluid v-if="studentData.isConfirm == 1">
      <v-alert type="error">
        <h4>هذا الطالب مثبت في النظام يرجى عدم تعديل البيانات</h4>
      </v-alert>
    </v-container>

    <div style="text-align: center" class="center-print">
      <v-img :src="require('../assets/logo.png')" class="my-3 image-print" contain height="200" />
      <h5>جامعة التراث</h5>
      <h5>نحافظ على القيم ونعد للمستقبل</h5>
      <h5>استمارة اجرائات التقديم والقبول الالكترونية للعام الدراسي 2022-2023</h5>
      <img id="barcode" />
    </div>
    <div v-if="show">
      <h5 style="text-align: center">رقم الاستمارة : {{ studentData.applicationNumber }}</h5>
      <div class="not-printable">
        <h3 style="padding: 5px; text-align: center; background: rgb(2, 87, 74); border-radius: 25px; margin: 10px; color: #ffffff">معلومات التسجيل في في نظام القبول المركزي</h3>
      </div>

      <div class="not-printable center-print" v-if="showBanner">
        <v-row>
          <v-col cols="2" style="text-align: center">
            <span class="copy">اسم المستخدم</span>
          </v-col>
          <v-col cols="3">
            <span @click="copyURL(`t_${studentData.collegeNumber}`)" style="cursor: pointer; color: #3c79af">t_{{ studentData.collegeNumber }}</span>
          </v-col>
          <v-col cols="2" style="text-align: center">
            <span>كلمة المرور</span>
          </v-col>
          <v-col cols="3">
            <span @click="copyURL(`${studentData.password}`)" style="cursor: pointer; color: #3c79af">{{ studentData.password }}</span>
          </v-col>
          <v-col cols="2">
            <span v-if="studentData.isRegistered == 1">تم التسجيل في نظام القبول المركزي</span>
            <v-btn v-else tile color="primary" @click="registerStudent()"> تسجيل في نظام القبول </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="center-print">
        <div>
          <h3 style="padding: 5px; text-align: center; background: rgb(2, 87, 74); border-radius: 25px; margin: 10px; color: #ffffff">المعلومات الشخصية والدراسية للطالب</h3>
        </div>

        <div style="padding: 5px">
          <v-row>
            <v-col cols="2" style="padding-right: 50px; text-align: right"> <span>كود الطالب</span></v-col>

            <v-col cols="4" style="text-align: right">
              <span>{{ studentData.collegeNumber }}</span>
            </v-col>

            <v-col cols="2"> <span>الاسم الرباعي</span></v-col>

            <v-col cols="4">
              <span @click="copyURL(`${studentData.studentName}`)" style="cursor: pointer; color: #3c79af">{{ studentData.studentName }}</span>
            </v-col>
          </v-row>
        </div>
        <v-divider style="width: 95%; margin: auto"></v-divider>

        <div style="padding: 5px">
          <v-row>
            <v-col cols="2" style="padding-right: 50px; text-align: right"> <span>اسم الام</span></v-col>
            <v-col cols="4" style="text-align: right">
              <span>{{ studentData.motherName }}</span>
            </v-col>
            <v-col cols="2"> <span>رقم الهاتف</span></v-col>
            <v-col cols="4" style="text-align: right">
              <span @click="copyURL(`${studentData.studentPhone}`)" style="cursor: pointer; color: #3c79af">{{ studentData.studentPhone }}</span>
            </v-col>
          </v-row>
        </div>
        <v-divider style="width: 95%; margin: auto"></v-divider>
        <div style="padding: 5px">
          <v-row>
            <v-col cols="2" style="padding-right: 50px; text-align: right"> <span>الجنس</span></v-col>
            <v-col cols="2" style="text-align: right">
              <span>{{ studentData.gender == 1 ? "ذكر" : "انثى" }}</span>
            </v-col>
            <v-col cols="2"> <span>التولد</span></v-col>
            <v-col cols="2" style="text-align: right">
              <span>{{ studentData.dob }}</span>
            </v-col>
            <v-col cols="2"> <span>رقم الوصل</span></v-col>
            <v-col cols="2" style="text-align: right">
              <span>{{ studentPay.length > 0 ? studentPay.filter((pay) => pay.paymentTypeId != 10)[0].voucherNumber : "بلا" }}</span>
            </v-col>
          </v-row>
        </div>
        <v-divider style="width: 95%; margin: auto"></v-divider>
        <div style="padding: 5px">
          <v-row>
            <v-col cols="3" style="padding-right: 50px; text-align: right"> <span>الرقم الامتحاني</span></v-col>
            <v-col cols="3" style="text-align: right">
              <span @click="copyURL(`${studentData.examNumber}`)" style="cursor: pointer; color: #3c79af">{{ studentData.examNumber }}</span>
            </v-col>
            <v-col cols="2"> <span>الرقم السري</span></v-col>
            <v-col cols="4" style="text-align: right">
              <span>{{ studentData.secretNumber }}</span>
            </v-col>
          </v-row>
        </div>
        <v-divider style="width: 95%; margin: auto"></v-divider>
        <div style="padding: 5px">
          <v-row>
            <v-col cols="2" style="padding-right: 50px; text-align: right"> <span>التربية</span></v-col>
            <v-col cols="4" style="text-align: right">
              <span>{{ studentData.education }}</span>
            </v-col>
            <v-col cols="2"> <span>اسم المدرسة</span></v-col>
            <v-col cols="4" style="text-align: right">
              <span>{{ studentData.schoolName }}</span>
            </v-col>
          </v-row>
        </div>
        <v-divider style="width: 95%; margin: auto"></v-divider>
        <div style="padding: 5px">
          <v-row>
            <v-col cols="2" style="padding-right: 50px; text-align: right"> <span>هوية الاحوال</span></v-col>
            <v-col cols="2">
              <span>{{ studentData.nationalNumber }}</span>
            </v-col>
            <v-col cols="2"> <span>المعدل</span></v-col>
            <v-col cols="2">
              <span>{{ studentData.average }}</span>
            </v-col>

            <v-col cols="2"> <span>المجموع</span></v-col>
            <v-col cols="2">
              <span>{{ studentData.total }}</span>
            </v-col>
          </v-row>
        </div>
        <v-divider style="width: 95%; margin: auto"></v-divider>
        <div style="padding: 5px">
          <v-row>
            <v-col cols="2" style="padding-right: 50px; text-align: right"> <span>الدور</span></v-col>
            <v-col cols="2">
              <span>{{ studentData.attempt }}</span>
            </v-col>
            <v-col cols="2"> <span>الفرع</span></v-col>
            <v-col cols="2">
              <span>{{ studentData.categoryName }}</span>
            </v-col>

            <v-col cols="2"> <span>سنة التخرج</span></v-col>
            <v-col cols="2">
              <span>{{ studentData.graduationYear }}</span>
            </v-col>
          </v-row>
        </div>
        <div class="center-print">
          <h3 style="padding: 5px; text-align: center; background: rgb(2, 87, 74); border-radius: 25px; margin: 10px; color: #ffffff">الاقسام المرشحة من قبل الطالب</h3>
        </div>

        <div style="padding: 5px" v-if="show">
          <v-row>
            <v-col cols="3" style="padding-right: 50px; text-align: right"> <span>الترشيح الاول</span></v-col>
            <v-col cols="3">
              <span>{{ studentData.firstSectionName }}</span>
            </v-col>
            <v-col cols="3">
              <span> {{ studentData.firstStudyType }}</span></v-col
            >
            <v-col cols="3">
              <span>{{ studentData.firstUniversity }}</span>
            </v-col>
          </v-row>
        </div>
        <v-divider style="width: 95%; margin: auto"></v-divider>
        <div style="padding: 5px">
          <v-row>
            <v-col cols="3" style="padding-right: 50px; text-align: right"> <span>الترشيح الثاني</span></v-col>
            <v-col cols="3">
              <span>{{ studentData.secondSectionName != "none" ? studentData.secondSectionName : "" }}</span>
            </v-col>
            <v-col cols="3">
              <span> {{ studentData.secondStudyType != "none" ? studentData.secondStudyType : " " }}</span></v-col
            >
            <v-col cols="3">
              <span>{{ studentData.secondUniversity != "none" ? studentData.secondUniversity : " " }}</span>
            </v-col>
          </v-row>
        </div>
        <v-divider style="width: 95%; margin: auto"></v-divider>
        <div style="padding: 5px">
          <v-row>
            <v-col cols="3" style="padding-right: 50px; text-align: right"> <span>الترشيح الثالث</span></v-col>
            <v-col cols="3">
              <span>{{ studentData.thirdSectionName != "none" ? studentData.thirdSectionName : " " }}</span>
            </v-col>
            <v-col cols="3">
              <span> {{ studentData.thirdStudyType != "none" ? studentData.thirdStudyType : " " }}</span></v-col
            >
            <v-col cols="3">
              <span>{{ studentData.thirdUniversity != "none" ? studentData.thirdUniversity : " " }}</span>
            </v-col>
          </v-row>
        </div>
        <v-divider style="width: 95%; margin: auto"></v-divider>

        <div style="padding: 5px">
          <v-row>
            <v-col cols="3" style="padding-right: 50px; text-align: right"> <span>الترشيح الرابع</span></v-col>
            <v-col cols="3">
              <span>{{ studentData.fourthSectionName != "none" ? studentData.fourthSectionName : " " }}</span>
            </v-col>
            <v-col cols="3">
              <span> {{ studentData.fourthStudyType != "none" ? studentData.fourthStudyType : " " }}</span>
            </v-col>
            <v-col cols="3">
              <span>{{ studentData.fourthUniversity != "none" ? studentData.fourthUniversity : " " }}</span>
            </v-col>
          </v-row>
        </div>

        <div class="center-print">
          <h3 style="padding: 5px; text-align: center; background: rgb(2, 87, 74); border-radius: 25px; margin: 10px; color: #ffffff">معلومات خاصة بلجنة القبول</h3>
        </div>

        <div style="padding: 5px" v-if="show">
          <v-row>
            <v-col cols="3" style="padding-right: 50px; text-align: right"> <span>قناة القبول</span></v-col>
            <v-col cols="3">
              <span>{{ studentData.acceptedName }}</span>
            </v-col>
            <v-col cols="3"> <span>الملاحظات</span></v-col>
            <v-col cols="3">
              <span>{{ studentData.notes }}</span>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
    <div style="text-align: center; margin: 10px" class="not-printable" v-if="show">
      <v-btn tile color="primary" @click="printPage()">
        <v-icon left> mdi-printer </v-icon>
        طباعة
      </v-btn>
    </div>
  </div>
</template>

<script>
var JsBarcode = require("jsbarcode");

export default {
  data() {
    return {
      overlay: false,
      show: false,
      tab: null,
      showBanner: false,
      search: "",
      applicationNumber: 0,
      studentPay: [],
      student: "",
      studentData: "",
      studentRequiredFormRules: [(v) => !!v || "حقل مطلوب . يرجى ادخال قيمة"],
      acceptedSection: [],
    };
  },
  created() {
    let spans = document.querySelectorAll(".copy");
    console.log("search");
    for (const span of spans) {
      span.onclick = function () {
        document.execCommand("copy");
      };

      span.addEventListener("copy", function (event) {
        event.preventDefault();
        console.log(event);
        if (event.clipboardData) {
          event.clipboardData.setData("text/plain", span.textContent);
          console.log(event.clipboardData.getData("text"));
        }
      });
    }
  },
  methods: {
    registerStudent() {
      let self = this;

      self.show = false;
      self.showBanner = false;
      self.$http
        .put(
          `${self.$store.state.apiUrlApplication}/registered/${self.studentData.idApplication}`,
          { student: "name" },
          {
            headers: { Authorization: `Bearer ${self.$store.state.user}` },
          }
        )
        .then((res) => {
          self.show = true;
          self.showBanner = true;
          console.log(res.data);
          self.studentData = res.data;
          // self.searchData();
        })
        .catch((e) => console.log(e));
    },
    searchData() {
      let self = this;
      self.show = false;
      self.showBanner = false;
      //
      self.$http
        .get(`${self.$store.state.apiUrlApplication}/applicationSearch/${self.student}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res.data);
          self.studentData = res.data;
          JsBarcode("#barcode", `${res.data.collegeNumber}`, {
            width: 3,
            height: 30,
            displayValue: false,
          });
          self.studentPay = [];
          self.$http
            .get(`${self.$store.state.apiUrlFees}/studentPaymentsDetailsInstallment/${res.data.collegeNumber}`, {
              headers: { Authorization: `Bearer ${self.$store.state.user}` },
            })
            .then((resPayment) => {
              console.log(resPayment.data);
              self.studentPay = resPayment.data;
            });

          self.show = true;
          self.showBanner = true;
        })
        .catch((err) => {
          this.$toasted.error("رقم الاستمارة او اسم الطالب غير موجود الرجاء التأكد من المعلومات");
          console.log(err);
        });
    },
    async copyURL(myText) {
      try {
        await navigator.clipboard.writeText(myText);
        console.log(myText);
      } catch ($e) {
        console.log($e);
      }
    },
    printPage() {
      window.print();
    },
  },
};
</script>

<style lang="scss" scoped>
@media print {
}
</style>
