<template>
  <div style="margin-top: 30px">
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div v-if="$store.state.userData.idEmployee == 907">
      <v-row align="center" justify="center" v-if="show">
        <v-col cols="6" class="pa-4">
          <v-card class="ma-2 radius-1 pa-5" elevation="6">
            <SectionChart :data="secondChartData" :labels="secondChartLabels" />
          </v-card>
        </v-col>
        <v-col cols="6" class="pa-4">
          <v-card class="ma-2 radius-1 pa-5" elevation="6">
            <CategoryChart :data="chartData" :labels="chartLabels" />
          </v-card>
        </v-col>
      </v-row>

      <div style="margin-top: 30px"></div>

      <template>
        <v-row justify="center">
          <v-card elevation="0" height="250" width="98vw">
            <v-overlay :absolute="absolute" :value="divOverlay"><v-progress-circular indeterminate size="64" color="accent"></v-progress-circular></v-overlay>
            <v-row v-if="showPaid">
              <v-col cols="4">
                <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 10px" max-width="344" color="accent">
                  <v-icon style="background: white; margin-top: -30px" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
                  <h4 class="warning-color" style="font-size: 1.4em; font-weight: lighter">مجموع الطلبة المسددين</h4>
                  <h4 class="warning-color" style="font-size: 2.5em; font-weight: lighter">
                    {{ studentsPaid.filter((student) => student.amount != null).length }}
                  </h4>
                </v-card>
              </v-col>

              <v-col cols="4">
                <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 10px" max-width="344" color="accent">
                  <v-icon style="background: white; margin-top: -30px" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-down</v-icon>
                  <h4 class="warning-color" style="font-size: 1.4em; font-weight: lighter">مجموع الطلبة الغير مسددين</h4>
                  <h4 class="warning-color" style="font-size: 2.5em; font-weight: lighter">
                    {{ studentsPaid.filter((student) => student.amount == null).length }}
                  </h4>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
        <div style="margin-top: 30px"></div>
      </template>

      <div style="margin-top: 30px"></div>
      <v-card elevation="6" class="mx-auto radius-1 mb-10" style="text-align: center; padding: 20px" max-width="344" color="primary">
        <h3 style="color: #ffffff">الاحصائيات</h3>
      </v-card>
      <div style="margin-top: 30px"></div>

      <v-row v-if="show">
        <v-col cols="4">
          <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 10px" max-width="344" color="accent">
            <v-icon style="background: white; margin-top: -30px" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon>
            <h4 class="warning-color" style="font-size: 1.4em; font-weight: lighter">عدد الاقسام</h4>
            <h4 class="warning-color" style="font-size: 2.5em; font-weight: lighter">
              {{ stats[0].length }}
            </h4>
          </v-card>
        </v-col>

        <v-col cols="4">
          <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 10px" max-width="344" color="accent">
            <v-icon style="background: white; margin-top: -30px" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-down</v-icon>
            <h4 class="warning-color" style="font-size: 1.4em; font-weight: lighter">مجموع الطلبة المتقدمين</h4>
            <h4 class="warning-color" style="font-size: 2.5em; font-weight: lighter">
              {{ stats[1][0].allStudents }}
            </h4>
          </v-card>
        </v-col>
      </v-row>

      <div style="margin-top: 30px"></div>
      <v-card elevation="6" class="mx-auto radius-1 mb-10" style="text-align: center; padding: 20px" max-width="344" color="primary">
        <h3 style="color: #ffffff">احصائيات الاقسام</h3>
      </v-card>
      <div style="margin-top: 30px"></div>

      <v-row v-if="show">
        <v-col cols="12" v-for="(dep, index) in stats[0]" :key="index">
          <v-card elevation="6" class="mx-auto radius-1" style="text-align: center; padding: 10px" max-width="90vw" color="accent">
            <v-alert border="left" class="mb-0" color="info">
              <v-row>
                <v-col cols="2">
                  <h5 class="warning-color" style="font-size: 1.2em; font-weight: lighter; display: inline-block">القسم</h5>
                </v-col>
                <v-col cols="4">
                  <h5 class="warning-color" style="font-size: 1.2em; font-weight: lighter; display: inline-block">
                    {{ dep.firstSectionName }}
                  </h5>
                </v-col>
                <v-col cols="2">
                  <h5 class="warning-color" style="font-size: 1.2em; font-weight: lighter">مجموع الطلبة</h5>
                </v-col>
                <v-col cols="4">
                  <h5 class="warning-color" style="font-size: 1.2em; font-weight: lighter">
                    {{ dep.numberOfStudents }}
                  </h5>
                </v-col>
              </v-row>
            </v-alert>
            <!-- <v-icon style="background: white; margin-top: 0px" class="pa-5 elevation-6 radius-1 mb-5" color="primary">mdi-archive-arrow-up</v-icon> -->
          </v-card>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <div style="margin-top: 30px"></div>
      <v-card elevation="6" class="mx-auto radius-1 mb-10" style="text-align: center; padding: 20px" max-width="344" color="primary">
        <h3 style="color: #ffffff">الصفحة الرئيسية</h3>
      </v-card>
      <div style="margin-top: 30px"></div>
    </div>
  </div>
</template>

<script>
import SectionChart from "./../components/SectionChart.vue";
import CategoryChart from "./../components/CategoryChart.vue";
export default {
  components: {
    SectionChart,
    CategoryChart,
  },
  name: "Home",
  data: () => ({
    advertisements: 0,
    sections: 0,
    categories: 0,
    overlay: false,
    absolute: true,
    divOverlay: false,
    showPaid: false,
    show: false,
    studentsPaid: [],
    stats: [],
    chartData: [],
    chartLabels: [],
    secondChartData: [],
    secondChartLabels: ["الاناث", "الذكور"],
  }),
  created() {
    this.initialization();
  },
  methods: {
    initialization() {
      let self = this;
      self.overlay = true;
      self.divOverlay = true;
      self.$http
        .get(`${self.$store.state.apiUrlApplication}/getFormStatistics`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } })
        .then((res) => {
          console.log(res.data);
          self.stats = res.data;
          self.chartData = res.data[0].map((count) => count.numberOfStudents);
          self.chartLabels = res.data[0].map((count) => count.firstSectionName);
          self.secondChartData = res.data[2].map((count) => count.studentsGender);
          self.overlay = false;
          self.show = true;
        })
        .catch((e) => {});

      self.$http
        .get(`${self.$store.state.apiUrlApplication}/getFormStatisticsPaid`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } })
        .then((res) => {
          console.log(res.data);
          self.studentsPaid = res.data;
          self.divOverlay = false;
          self.showPaid = true;
        })
        .catch((e) => {});
    },
  },
};
</script>
