<template>
  <div>
    <v-container class="container" fluid>
      <v-card elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="10">
            <h3>صفحة التقارير</h3>
          </v-col>
          <v-col cols="2" align="left" v-if="$store.state.userData.idEmployee == 907">
            <SearchReport style="display: inline-block" @clicked="onSearch" />
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mx-2" fab small color="primary" @click="exportExcel()" v-bind="attrs" v-on="on">
                  <v-icon> mdi-microsoft-excel </v-icon>
                </v-btn>
              </template>
              <span>تصدير EXCEL</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="mx-2" fab small color="primary" @click="exportExcelDifferent()" v-bind="attrs" v-on="on">
                  <v-icon> mdi-file-excel </v-icon>
                </v-btn>
              </template>
              <span>تصدير اختلاف الوصل بالقسم</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container class="container" fluid>
      <v-card elevation="6">
        <v-card-title>
          <v-text-field v-model="search" outlined append-icon="mdi-magnify" label="بحث" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="students" :items-per-page="10" :search="search" item-key="idStudent" class="table-content" :no-data-text="dataMessage">
          <template v-slot:[`item.index`]="{ index }">
            <h4 style="padding: 0 3px">{{ index + 1 }}</h4>
          </template>
          <template v-slot:[`item.gender`]="{ item }">
            <h4 style="padding: 0 3px">
              {{ (item.gender = 2 ? "انثى" : "ذكر") }}
            </h4>
          </template>

          <template v-slot:[`item.level`]="{}">
            <h4 style="padding: 0 3px">المرحلة الاولى</h4>
          </template>

          <template v-slot:[`item.userName`]="{ item }">
            <h4 style="padding: 0 3px">t_{{ item.studentCollegeNumber }}</h4>
          </template>

          <template v-slot:[`item.paid`]="{ item }">
            <h4 style="padding: 0 3px" v-if="item.amount != null">مسدد</h4>
            <h4 style="padding: 0 3px" v-else>غير مسدد</h4>
          </template>

          <template v-slot:[`item.voucherNumber`]="{ item }">
            <h4 style="padding: 0 3px" v-if="item.amount != null">{{ item.voucherNumber }}</h4>
            <h4 style="padding: 0 3px" v-else>لايوجد</h4>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import SearchReport from "../components/SearchReport.vue";
const Excel = require("exceljs");
export default {
  components: {
    SearchReport,
  },
  data: () => ({
    search: "",
    selected: [],
    students: [],
    dataMessage: "الرجاء البحث عن طلبة",
    headers: [
      { text: "التسلسل", value: "index" },
      { text: "اسم الطالب", value: "studentName" },
      { text: "رقم الاستمارة", value: "applicationNumber" },
      { text: "القسم", value: "firstSectionName" },
      { text: "قناة القبول", value: "acceptedName" },
      { text: "حساب الطالب", value: "userName" },
      { text: "رقم الهاتف", value: "studentPhone" },
      { text: "الملاحظات", value: "formNotes" },
      { text: "الحالة المالية", value: "paid" },
      { text: "القسم الذي دفع له", value: "sectionName" },
      { text: "رقم الوصل", value: "voucherNumber" },
      {
        text: "نوع الدراسة",
        value: "firstStudyType",
      },
    ],
  }),
  created() {},
  methods: {
    onSearch(value) {
      let self = this;
      if (value.students.length > 0) {
        if (value.statusId == 3) {
          self.students = value.students;
        } else if (value.statusId == 2) {
          self.students = value.students.filter((student) => student.amount == null);
        } else {
          self.students = value.students.filter((student) => student.amount != null);
        }
      } else {
        self.dataMessage = "لايوجد طلبة في القسم";
      }
    },
    exportExcelDifferent() {
      let self = this;
      let studentDifferent = self.students.filter((student) => student.sectionName != student.firstSectionName);
      console.log(studentDifferent);

      if (studentDifferent.length > 0) {
        const workbook = new Excel.Workbook();

        const worksheet = workbook.addWorksheet("استمارات القبول");
        worksheet.views = [{ rightToLeft: true }];

        worksheet.columns = [
          { header: "اسم الطالب", key: "studentName", width: 50 },
          { header: "رقم الاستمارة", key: "applicationNumber", width: 20 },
          { header: "القسم", key: "sectionNameOne", width: 50 },
          { header: "قناة القبول", key: "acceptedName", width: 50 },
          { header: "حساب الطالب", key: "userName", width: 20 },
          { header: "رقم الهاتف", key: "studentPhone", width: 50 },
          { header: "الملاحظات", key: "notes", width: 50 },
          { header: "نوع الدراسة", key: "studyType", width: 50 },
          { header: "الحالة المادية", key: "paid", width: 50 },
          { header: "القسم الذي سدد له", key: "sectionName", width: 50 },
          { header: "رقم الوصل", key: "voucherNumber", width: 20 },
        ];
        studentDifferent.forEach((student) => {
          worksheet.addRow({
            studentName: student.studentName,
            applicationNumber: student.applicationNumber,
            sectionNameOne: student.firstSectionName,
            acceptedName: student.acceptedName,
            userName: `t_${student.studentCollegeNumber}`,
            studentPhone: student.studentPhone,
            notes: student.formNotes,
            studyType: student.firstStudyType,
            paid: student.amount != null ? "مسدد" : "غير مسدد",
            sectionName: student.sectionName,
            voucherNumber: student.voucherNumber != null ? student.voucherNumber : " ",
          });
        });

        worksheet.eachRow(function (row, rowNumber) {
          row.eachCell((cell) => {
            cell.alignment = { vertical: "middle", horizontal: "center" };
          });
        });

        worksheet.getRow(1).eachCell((cell) => {
          cell.font = { bold: true };
          cell.alignment = { vertical: "middle", horizontal: "center" };
          cell.fill = {
            type: "pattern",
            pattern: "darkTrellis",
            fgColor: { argb: "FFFFFF00" },
            bgColor: { argb: "FF0000FF" },
          };
        });

        workbook.xlsx.writeBuffer().then((data) => {
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.setAttribute("style", "display: none");
          a.href = url;
          a.download = `differentStudentVoucher.xlsx`;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        });
      } else {
        this.$toasted.error("لايوجد طلبة");
      }
    },
    exportExcel() {
      let self = this;
      const workbook = new Excel.Workbook();

      const worksheet = workbook.addWorksheet("استمارات القبول");
      worksheet.views = [{ rightToLeft: true }];

      worksheet.columns = [
        { header: "اسم الطالب", key: "studentName", width: 50 },
        { header: "رقم الاستمارة", key: "applicationNumber", width: 20 },
        { header: "القسم", key: "sectionNameOne", width: 50 },
        { header: "قناة القبول", key: "acceptedName", width: 50 },
        { header: "حساب الطالب", key: "userName", width: 20 },
        { header: "رقم الهاتف", key: "studentPhone", width: 50 },
        { header: "الملاحظات", key: "notes", width: 50 },
        { header: "نوع الدراسة", key: "studyType", width: 50 },
        { header: "الحالة المادية", key: "paid", width: 50 },
        { header: "القسم الذي سدد له", key: "sectionName", width: 50 },
        { header: "رقم الوصل", key: "voucherNumber", width: 20 },
      ];
      self.students.forEach((student) => {
        worksheet.addRow({
          studentName: student.studentName,
          applicationNumber: student.applicationNumber,
          sectionNameOne: student.firstSectionName,
          acceptedName: student.acceptedName,
          userName: `t_${student.studentCollegeNumber}`,
          studentPhone: student.studentPhone,
          notes: student.formNotes,
          studyType: student.firstStudyType,
          paid: student.amount != null ? "مسدد" : "غير مسدد",
          sectionName: student.sectionName,
          voucherNumber: student.voucherNumber != null ? student.voucherNumber : " ",
        });
      });

      worksheet.eachRow(function (row, rowNumber) {
        row.eachCell((cell) => {
          cell.alignment = { vertical: "middle", horizontal: "center" };
        });
      });

      worksheet.getRow(1).eachCell((cell) => {
        cell.font = { bold: true };
        cell.alignment = { vertical: "middle", horizontal: "center" };
        cell.fill = {
          type: "pattern",
          pattern: "darkTrellis",
          fgColor: { argb: "FFFFFF00" },
          bgColor: { argb: "FF0000FF" },
        };
      });

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = `applicationReport.xlsx`;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      });
    },
    selectStudent(student) {
      console.log(student);
    },
  },
  filters: {
    setLevel: function (level) {
      if (level == 1) {
        return "المرحلة الاولى";
      } else if (level == 2) {
        return "المرحلة الثانية";
      } else if (level == 3) {
        return "المرحلة الثالثة";
      } else if (level == 4) {
        return "المرحلة الرابعة";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
