<template>
  <div>
    <canvas id="category-chart" width="100"></canvas>
  </div>
</template>

<script>
import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale
);

export default {
  name: "SectionChart",
  props: {
    labels: Array,
    data: Array,
  },
  data: () => ({
    sectionData: {
      type: "bar",
      data: {
        labels: ["صادر", "وارد", "مذكرات داخلية", "اوامر ادارية"],
        datasets: [
          {
            label: "الكتب",
            data: [0, 0, 1],
            backgroundColor: "#26547c",
            borderColor: "#26547c",
            borderWidth: 3,
          },
          {
            label: "انواع الكتب",
            data: [0.166, 2.081, 3.003],
            backgroundColor: "#266462",
            borderColor: "#266462",
            borderWidth: 3,
          },
        ],
      },
      options: {
        responsive: true,
        lineTension: 1,
      },
    },
  }),
  mounted() {
    const ctx = document.getElementById("category-chart");
    let data = {
      type: "bar",
      data: {
        labels: this.labels,
        datasets: [
          {
            label: "الكتب",
            data: this.data,
            backgroundColor: "#1b998b",
            borderColor: "#1b998b",
            borderWidth: 3,
          },
        ],
      },
      options: {
        responsive: true,
        lineTension: 1,
      },
    };

    new Chart(ctx, data);
  },
};
</script>
