import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    apiUrl: "https://student.janat-albelad.com/api",
    apiUrlApplication: "https://form.janat-albelad.com/api",
    // apiUrlApplication: "http://localhost:4340/api",
    apiUrlEmployee: "https://employee.janat-albelad.com/api",
    apiUrlFees: "https://fees.janat-albelad.com/api",
    sections: null,
    years: null,
    user: null,
    categories: null,
    studyTypes: [{ name: "بلا" }, { name: "صباحي" }, { name: "مسائي" }],
    genders: [{ name: "ذكر" }, { name: "انثى" }],
    educations: [
      { name: "الكرخ الاولى" },
      { name: "الكرخ الثانية" },
      { name: "الكرخ الثالثة" },
      { name: "الرصافة الاولى" },
      { name: "الرصافة الثانية" },
      { name: "الرصافة الثالثة" },
      { name: "غير ذلك" },
    ],
    attempts: [{ name: "الدور الاول" }, { name: "الدور الثاني" }, { name: "الدور الثالث" }, { name: "دور تكميلي" }],
    userData: null,
    studentPay: {},
    printInformation: "",
    reportFees: "",
    studentDetails: null,
    subFeeStudentDetails: null,
  },
  mutations: {
    GET_USER_DATA(state, userData) {
      state.user = userData;
    },
  },
  actions: {
    getUserDate({ commit }) {
      let data = localStorage.getItem("employee");

      if (data) {
        var base64Url = data.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );

        let text = JSON.parse(jsonPayload);
        console.log(text);
        this.state.userData = text;
      }
      commit("GET_USER_DATA", JSON.parse(data));
    },
  },
  modules: {},
});
