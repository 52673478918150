<template>
  <div>
    <v-container class="container not-printable" fluid>
      <v-card elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="12">
            <h3>الاستمارات</h3>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-container class="container not-printable" fluid>
      <v-card elevation="6">
        <v-row style="margin: 10px">
          <v-col cols="12">
            <v-tabs background-color="primary" center-active dark centered v-model="tab" style="border-radius: 5px">
              <v-tab v-if="$store.state.userData.roleId != 61">استمارة قبول جديدة</v-tab>
              <v-tab>تعديل استمارة</v-tab>
              <v-tab>بحث عن استمارة</v-tab>
            </v-tabs>
          </v-col>
        </v-row>
      </v-card>
    </v-container>

    <v-tabs-items v-model="tab" class="center-print">
      <v-tab-item v-if="$store.state.userData.roleId != 61"><AddStudent /></v-tab-item>
      <v-tab-item><EditApplication /></v-tab-item>
      <v-tab-item><SearchApplication /></v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import EditApplication from "../components/EditApplication.vue";
import SearchApplication from "../components/SearchApplication.vue";
import AddStudent from "../components/AddStudent.vue";
export default {
  components: {
    SearchApplication,
    EditApplication,
    AddStudent,
  },
  data() {
    return {
      tab: null,
    };
  },
};
</script>

<style lang="scss" scoped>
.tabs__content {
  min-height: 100vh;
}
</style>
