<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app class="pt-4 not-printable" color="primary" mini-variant fixed right v-if="$store.state.user != null">
      <!-- <v-avatar v-for="(item, i) in items" :key="i" class="d-block text-center mx-auto mb-9"> <v-icon v-text="item.icon" color="white"></v-icon></v-avatar>
      <v-avatar @click="logout">
        <v-icon left color="white"> mdi-account-circle </v-icon>
      </v-avatar> -->
      <v-list>
        <v-subheader></v-subheader>
        <v-list-item-group v-model="selectedItem" color="accent">
          <v-list-item v-for="(item, i) in items" :key="i" style="padding: 10px 15px" @click="page(item)">
            <v-tooltip left color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-text="item.icon" v-bind="attrs" v-on="on" color="white"></v-icon>
              </template>
              <span>{{ item.text }}</span>
            </v-tooltip>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-icon left color="white" @click="logoutPage()"> mdi-account-circle </v-icon>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app color="primary" v-if="$store.state.user != null" class="not-printable">
      <div class="d-flex align-center" style="width: 100%">
        <div style="text-align: right">
          <v-avatar color="secondary" size="50">
            <v-img :src="require('./assets/logo.png')" class="my-3" contain />
          </v-avatar>
        </div>
        <v-spacer></v-spacer>
        <h5 style="color: aliceblue">برنامج استمارات القبول</h5>
        <v-spacer></v-spacer>
        <h5 style="color: aliceblue">كلية التراث الجامعة</h5>
      </div>
    </v-app-bar>

    <v-main class="adjust-main center-print">
      <div>
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    selectedItem: 0,
    drawer: true,
    login: false,
    items: [
      { title: "/", text: "الرئيسية", icon: "mdi-home" },
      {
        title: "/application",
        text: "استمارة التسجيل",
        icon: "mdi-account-cash",
      },
      { title: "/form", text: "البحث داخل الاستمارات", icon: "mdi-text-box-search" },
      { title: "/all", text: "جميع الاستمارات", icon: "mdi-file-document-multiple" },
      { title: "/report", text: "التقارير", icon: "mdi-file-chart" },
    ],
  }),
  created() {
    this.$store
      .dispatch("getUserDate")
      .then(() => {
        if (this.$store.state.user) {
          this.login = true;
          this.$router.push("/").catch(() => {});
          this.initialization();
        } else {
          this.$router.push("/login").catch(() => {});
          this.login = false;
        }
      })
      .catch(() => {});
  },

  methods: {
    initialization() {
      let self = this;

      self.$http
        .all([
          self.$http.get(`${self.$store.state.apiUrl}/sections`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
          self.$http.get(`${self.$store.state.apiUrl}/yearStudies`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
          self.$http.get(`${self.$store.state.apiUrlApplication}/allCategory`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
          self.$http.get(`${self.$store.state.apiUrl}/acceptedTypes`, { headers: { Authorization: `Bearer ${self.$store.state.user}` } }),
        ])
        .then(
          self.$http.spread((sections, years, categories, acceptedTypes) => {
            self.$store.state.sections = [{ sectionName: "بلا" }, ...sections.data];
            console.log(self.$store.state.sections);
            self.$store.state.years = years.data;
            self.$store.state.categories = categories.data;
            self.$store.state.acceptedTypes = acceptedTypes.data;
            console.log(sections);
          })
        )
        .catch((e) => {
          console.log(e);
          this.$toasted.error("حدث خطأ الرجاء التأكد من الاتصال بالانترنت");
        });
    },
    page(item) {
      this.$router.push(item.title).catch(() => {});
    },
    logoutPage() {
      // salam2@turath.edu.iq
      localStorage.removeItem("employee");
      location.reload();
    },
  },
};
</script>
<style>
@import url("./font.css");

@media print {
  @page {
    size: portrait;
    margin: 0.5cm;
  }
  @page :left {
    margin: 0.5cm;
  }

  @page :right {
    margin: 0.5cm;
  }
  .not-printable {
    display: none !important;
  }
  body {
    -webkit-print-color-adjust: exact !important;
    height: 100% !important;
    width: 100% !important;
    padding: 0px !important;
    margin: 0px !important;
    color: #000;
    background-color: #fff !important;
  }
  .adjust-main {
    padding: 0px !important;
  }
  header nav,
  footer {
    display: none;
  }
  h3 {
    font-size: 12px !important;
  }
  h5 {
    font-size: 14px !important;
  }

  #barcode {
    width: 200px !important;
    height: 30px !important;
  }

  .center-print {
    background: #ffffff !important;
  }

  img {
    width: 130px !important;
  }
  .image-print {
    width: 120px !important;
    height: 120px !important;
    text-align: center !important;
    margin: auto !important;
  }
  span {
    font-size: 12px !important;
    font-weight: bolder !important;
  }
  .center-print {
    text-align: center !important;
  }
}
</style>
